import React, {useState} from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp/src";


const EmailListForm = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        addToMailchimp(email)
            .then((data) => {
                setMessage(data.msg)
            })
    };

    const handleEmailChange = (event) => {
        setEmail(event.currentTarget.value);
    };

    return (
        <form onSubmit={handleSubmit} className={"EmailListForm"}>
            {!message ?(<div>
                <h2>Subscribe</h2>
                <p>Receive email updates on the latest Tyve news, articles, and resources. Unsubscribe anytime.</p>
                <div className="Wrapper">
                    <input
                        placeholder="Email address"
                        name="email"
                        type="text"
                        onChange={handleEmailChange}
                    />
                    <button type="submit">Subscribe</button>
                </div>
            </div>) : (
               <span dangerouslySetInnerHTML={{__html: message}}/>
            )}
            <p className="small"> We'll never share your details. See our <a href="https://www.iubenda.com/privacy-policy/97836779">Privacy Policy</a>
            </p>
        </form>
    );
};

export default EmailListForm;
