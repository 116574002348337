import React from "react"
import {Link, graphql} from "gatsby"
import Img from 'gatsby-image';

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailListForm from "../components/email"

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = this.props.data.site.siteMetadata.title;
        const {previous, next} = this.props.pageContext;
        const featuredImage = post.frontmatter.featuredImage ? post.frontmatter.featuredImage.childImageSharp.fluid : null;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                    featuredImage={featuredImage}
                />
                <article>
                    <header>
                        <h1> {post.frontmatter.title} </h1>
                        <p className="caption"> {post.frontmatter.date} </p>
                        {featuredImage && <Img fluid={featuredImage}/>}
                    </header>
                    <section dangerouslySetInnerHTML={{__html: post.html}}/>
                    <hr/>
                    <footer>
                        <Bio/>
                    </footer>
                </article>
                <EmailListForm />
                <nav>
                    <ul
                        style={{
                            display: `flex`,
                            flexWrap: `wrap`,
                            justifyContent: `space-between`,
                            listStyle: `none`,
                            padding: 0,
                        }}
                    >
                        <li>
                            {previous && (
                                <Link to={previous.fields.slug} rel="prev">
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link to={next.fields.slug} rel="next">
                                    {next.frontmatter.title} →
                                </Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
            childImageSharp {
                fluid(maxWidth: 630, maxHeight: 400, cropFocus: NORTH) {
                  ...GatsbyImageSharpFluid
                }
              }
         }
      }
    }
  }
`
